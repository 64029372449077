import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Collapse, Breadcrumb, Tag } from 'antd';
import '../../node_modules/antd/dist/antd.css';
import './careers.css';

const { Panel } = Collapse;

const profileIntroText = {
    engineering: 'At Rummo, our Engineering team follows an event-driven serverless architecture approach to build, maintain and grow this new mobility service...',
    operations: 'operations text',
    marketing: 'marketing text',
    product: 'product text'
};

const profiles = {
    profile1: {
        title: 'Senior Fullstack Software Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'Senior-Fullstack-Software-Engineer'
    },
    profile2: {
        title: 'Senior Backend Software Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'Senior-Backend-Software-Engineer'
    },
    profile3: {
        title: 'Fullstack Software Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'Fullstack—Software-Engineer'
    },
    profile4: {
        title: 'Backend Software Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'Backend-Software-Engineer'
    },
    profile5: {
        title: 'Frontend Software Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'Frontend-Software-Engineer'
    },
    profile6: {
        title: 'QA Automation Engineer',
        area: 'Engineering',
        office: 'Lisbon',
        pageUri: 'QA-Automation-Engineer'
    },
    profile7: {
        title: 'UX/UI Designer',
        area: 'Product',
        office: 'Lisbon',
        pageUri: 'UX-UI-Designer'
    },
    profile8: {
        title: 'Head of Operations',
        area: 'Operations',
        office: 'Lisbon',
        pageUri: 'Head-of-Operations'
    },
    profile9: {
        title: 'Customer Support Manager',
        area: 'Operations',
        office: 'Lisbon',
        pageUri: 'Customer-Support-Manager'
    },
    profile10: {
        title: 'Senior Customer Support Representative',
        area: 'Operations',
        office: 'Lisbon',
        pageUri: 'Senior-Customer-Support-Representative'
    },
    profile11: {
        title: 'Customer Support Representative',
        area: 'Operations',
        office: 'Lisbon',
        pageUri: 'Customer-Support-Representative'
    },
    profile12: {
        title: 'Office Manager',
        area: 'Operations',
        office: 'Lisbon',
        pageUri: 'Office-Manager'
    },
    profile14: {
        title: 'Operations Manager',
        area: 'Operations',
        office: 'New York',
    },
    profile16: {
        title: 'Business Development Manager',
        area: 'Operations',
        office: 'New York',
    },
    profile17: {
        title: 'Product Marketing Manager',
        area: 'Marketing',
        office: 'New York',
    },
    profile18: {
        title: 'Office Manager',
        area: 'Operations',
        office: 'New York',
    }
}

function RenderPanels(index, title, area, office, excerpt, linkUrl) {
    let color, introText;
    switch (area) {
        case 'Engineering':
            color = 'geekblue';
            introText = profileIntroText.engineering
            break;
        case 'Operations':
            color = 'red';
            introText = profileIntroText.operations
            break;
        case 'Marketing':
            color = 'green';
            introText = profileIntroText.marketing
            break;
        case 'Product':
            color = 'purple';
            introText = profileIntroText.product
            break;
        default:
            color = 'geekblue';
            introText = profileIntroText.engineering
    }

    if (!linkUrl) {
        linkUrl = title.replace(/\W+/g, '-').toLowerCase() + '-' + office.replace(/\W+/g, '-').toLowerCase();
    }

    console.log(linkUrl);

    return (
        <Panel header={
            <panelHeader className='panelHeader'>
                <panelHeaderCol>
                    {title}
                </panelHeaderCol>
                <panelHeaderCol style={{ position: 'absolute', right: '35%' }}>
                    <div style={{ width: '100px', textAlign: 'center' }}>
                        <Tag color={color}>{area}</Tag>
                    </div>
                </panelHeaderCol>
                <panelHeaderCol style={{ color: 'gray', textAlign: 'right' }}>
                    {office}
                </panelHeaderCol>
            </panelHeader>
        } key={index}>
            <div className='row'>
                {excerpt}
            </div>
            <div className='row'>
                <Link to={linkUrl} className='readMoreButton'>Read More</Link>
            </div>
        </Panel>
    )
}

export default function SecondPage({ data }) {
    return (
        <Layout>
            <SEO title="Careers" />

            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Careers</Breadcrumb.Item>
            </Breadcrumb>

            <h2>Careers</h2>

            <div className="careersHero">
                <div className='careersHeroContainer'>
                    <h2 className='careersHeroTitle'>We're building a winning team.<br />Join us.</h2>
                    <p>
                        At Rummo, engineering and operations are the lifeblood of our business. It represents all that our company embodies:
                        Innovation, tenacity, creativity, inspiration, hard work, innovation, and fun.
                </p>
                </div>
            </div>

            <h2 className="sectionTitle">Apply Today</h2>
            <h4>{data.allMarkdownRemark.totalCount} Job Posts</h4>
            <Collapse>
                {data.allMarkdownRemark.edges.map(({ node }) => (

                    RenderPanels(node.id, node.frontmatter.title, node.frontmatter.area, node.frontmatter.office, node.excerpt, node.fields.slug)

                ))}
            </Collapse >
        </Layout >
    )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            area
            office
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }`